import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import {generatePath, useNavigate} from "react-router-dom";

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    action: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'seller',
    },
    {
        title: 'Units Sold',
        dataIndex: 'units',
        key: 'units',
    },
    {
        title: 'Buyers',
        key: 'buyers',
        dataIndex: 'buyers'
    },
];

type Props = {
    data: any[];
};

const TopSellingProducts: React.FC<Props> = ({data}) => {
    const navigate = useNavigate();

    return (
        <>
            <Table columns={columns}
                   dataSource={data.sort((a,b) => (b.units - a.units))}
                   onRow={(record, rowIndex) => {
                       return {
                           onClick: event => {
                               navigate(
                                   generatePath('/products/:id', {id: record.productId})
                               )
                           }, // click row
                       };
                   }}
                   pagination={{ defaultPageSize: 7, showSizeChanger: false, pageSizeOptions: ['7']}}/>
        </>
    )
}

export default TopSellingProducts;
