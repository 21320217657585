import React, {useRef, useState} from "react";
import {Card, Input, Button, Link, Loading} from "@nextui-org/react";
import {MDBContainer, MDBRow} from "mdbreact";
import { MDBCardBody, MDBCol } from 'mdbreact';
import shopped from "../images/shopped.png"
import {Form} from "antd";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Firebase from "../Firebase/Firebase";
import { Toast } from 'primereact/toast';



const firebaseAuth = getAuth(Firebase);
const ForgotPassword = () => {
    const toast = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const showSuccess = () => {
        // @ts-ignore
        toast.current.show({severity:'success', summary: 'Success Message', detail:'Email Sent. ' +
                'Check your email account for further instructions to reset your password', life: 3000});
    }

    const showError = () => {
        // @ts-ignore
        toast.current.show({severity:'error', summary: 'Error Message', detail:'Unable to send email: ' + errorMessage, life: 3000});
    }


    const handleReport = (values: any) => {
        setLoading(true);
        sendPasswordResetEmail(firebaseAuth, values.email)
            .then(() => {
                setLoading(false);
                showSuccess();
            })
            .catch((error: { code: any; message: any; }) => {
                const errorCode = error.code;
                setLoading(false);
                setErrorMessage(error.message);
                showError();
                // ..
            });
    }
    return (
        <div className="vh-100 bg">

            <Toast ref={toast} />

            <MDBContainer >
                <MDBRow justify={"center"} align={"center"} center={true}>
                    <MDBCol md="12" className="my-5">
                        <Card style={{width:"25rem"}} isHoverable className="my-5 p-2">
                            <MDBCardBody>
                                <img src={shopped} style={{width:"15rem", height:"3.5rem"}} className="rounded mx-auto mb-5 d-block" alt="aligment" />
                                <Form
                                    onFinish={handleReport}
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Please input email!' },
                                            {
                                                pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                                message: "Not a valid email address!"}
                                        ]}
                                    >
                                        <Input
                                            type="email"
                                            size="lg"
                                            aria-label={"email"}
                                            width={"100%"}
                                            onChange={()=>{}}
                                            placeholder="Your email address"
                                            className="my-1"
                                        />
                                    </Form.Item>

                                    <Button shadow disabled={loading} type="submit" className="w-100 bg-indigo-500 my-4" >
                                        {
                                            loading ? <Loading type="spinner" color="currentColor" size="sm" /> : <>Send</>
                                        }
                                    </Button>
                                </Form>
                                <div className="text-center text-black-50 d-flex justify-content-center">
                                    Go back to Login &nbsp;<Link href="/"> click here</Link>
                                </div>
                            </MDBCardBody>
                        </Card>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    )
}

export default ForgotPassword;
