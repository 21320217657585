import React, {useEffect} from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';


interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    status: JSX.Element;
    date: string;
    payment: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'seller',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Buyer',
        dataIndex: 'buyer',
        key: 'buyer',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Payment',
        key: 'payment',
        dataIndex: 'payment'
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
];


type Props = {
    data: any[];
};

const TransactionTable: React.FC<Props> = ({data}) => {
    useEffect(() => {
        //console.log(data);
    }, [data])
    return (
        <>
            <Table columns={columns}
                   dataSource={data}
                   pagination={{ defaultPageSize: 8, showSizeChanger: true, pageSizeOptions: ['7','49','63']}}/>
        </>
    )
}

export default TransactionTable;
