import React, {useEffect, useRef, useState} from 'react';
import {Form} from "antd";
import {Button, Input, Loading, Modal} from "@nextui-org/react";
import {Image, useToast} from "@chakra-ui/react";
import holder from "../../images/plainHolder.jpg";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Firebase from "../../Firebase/Firebase";
import Firefetch from "../../Firebase/Firefetch";


type Props = {
    openModal: boolean;
    closeModal: (val: boolean) => void;
    category: any;
};

var storage = getStorage(Firebase);
const EditCategoryModal: React.FC<Props> = ({openModal, closeModal, category}) => {

    const [file, setFile] = useState<Blob>();
    const fileUpload = useRef(null);
    const [categoryFile, setCategoryFile] = useState<string>( category?.imageUrl );
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();

    useEffect(() => {
        setCategoryFile( category?.imageUrl );
    }, [category]);

    const categoryUploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setCategoryFile(URL.createObjectURL(e.target.files[0]));
    }

    const handleUpload = () => {
        // @ts-ignore
        fileUpload.current.click()
    };

    const editCategory = (values: any) => {
        setLoading(true);

        var ID = category?.id;
        var catObject = {
            name: values.categoryName,
            imageUrl: category?.imageUrl,
        };

        uploadBytes(ref(storage, `categories/${ID}`), file!).then((item) => {
            getDownloadURL(item.ref)
                .then((url) => {

                    catObject.imageUrl = url;
                    Firefetch.UpdateInDB("categories", ID, catObject)
                        .then((result) => {
                            if(result == "success"){
                                toast({
                                    position: 'top',
                                    title: 'Category edited!',
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                });
                                setLoading(false);
                                closeModal(false);
                            }

                        });
                })
        });
    }

    return (
        <div>
            <Modal
                closeButton
                aria-labelledby="edit-category"
                open={openModal}
                onClose={() => {closeModal(false)}}
            >
                <h1>Edit Category</h1>
                <Form
                    layout={"vertical"}
                    onFinish={editCategory}
                    initialValues={
                        {
                            categoryName: category?.name,
                        }
                    }
                >
                    <div className="p-3">
                        <Form.Item
                            name="categoryIcon"
                            label={<b>Category Icon</b>}
                            rules={[
                                { required: false, message: 'Please select category icon file!' },
                            ]}
                        >

                                <input
                                    type="file"
                                    ref={fileUpload}
                                    accept="image/*"
                                    onChange={categoryUploadFile}
                                    style={{ opacity: "0" }}
                                />
                                <Image
                                    width={100}
                                    height={"50%"}
                                    src={categoryFile}
                                    className="d-flex align-self-center"
                                    fallbackSrc={holder}
                                    onClick={() => {
                                        handleUpload()
                                    }}
                                    alt='profile'
                                />


                        </Form.Item>
                        <Form.Item
                            name="categoryName"
                            label={<b>Category Name</b>}
                            rules={[
                                { required: false, message: 'Please input categoryName!' },
                            ]}
                        >
                            <Input
                                clearable
                                bordered
                                fullWidth
                                color="primary"
                                size="lg"
                                className="mt-3"
                                placeholder="category name"
                                contentLeft={<i className="pi pi-plus"/>}
                            />
                        </Form.Item>

                        <Button auto disabled={loading} type="submit" className=" my-4">

                            {
                                loading ? <Loading type="spinner" color="currentColor" size="sm" /> : <>Edit</>
                            }
                        </Button>
                    </div>
                </Form>
            </Modal>

        </div>
    )
}

export default EditCategoryModal;
