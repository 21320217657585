import React, {useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import {useNavigate} from "react-router-dom";

type Props = {
    buyers: number;
    sellers: number;
    admins: number;

}
const SystemUsersPieChart : React.FC<Props> = ({buyers, sellers, admins}) => {

    const navigate = useNavigate();
    const [chartData, setChartData] = useState({
        labels: ['Buyers', 'Sellers', 'Admins'],
        datasets: [
            {
                data: [buyers, sellers, admins],
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D"
                ]
            }
        ]
    });

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        'onClick': function (evt: any, item: any) {
            console.log('legend onClick', evt);
            console.log('legend onClick', item[0].index);
            console.log('legd item', chartData.datasets[0].data);

            if(item[0].index == 0){
                navigate("/buyers");

            } else if(item[0].index == 1){
                navigate("/shops");

            } else {
                navigate("/profile");
            }

        }
    });

    useEffect(() => {
        setChartData(
            {
                labels: ['Buyers', 'Sellers', 'Admins'],
                datasets: [
                    {
                        data: [buyers, sellers, admins],
                        backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
                            "#FFA726"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D"
                        ]
                    }
                ]
            }
        );
    }, [admins, buyers, sellers]);

    return (
        <div className="card flex justify-content-center align-items-center">
            <Chart type="pie" data={chartData} options={lightOptions} style={{ width: '80%' }} />
        </div>
    )
}

export default SystemUsersPieChart;
