import React, {useEffect, useRef, useState} from 'react';
import {useToast} from "@chakra-ui/react";
import {Form} from "antd";
import {Loading, Tooltip} from "@nextui-org/react";
import moment from "moment";
import Firefetch from "../../Firebase/Firefetch";
import { Select } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import holder from '../../images/plainHolder.jpg';
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Firebase from "../../Firebase/Firebase";
import { Modal, Button, Text, Input} from "@nextui-org/react";


type Props = {
    openDrawer: boolean;
    closeDrawer: (val: boolean) => void;
    contentType: string;
    parentCategory: string;
    products: any[];
};

var storage = getStorage(Firebase);
const AddContent: React.FC<Props> = ({openDrawer, closeDrawer, contentType, products, parentCategory}) => {
    const toast = useToast();
    const [contentForm] = Form.useForm();
    const [saveLoading, setSaveLoading] = useState(false);
    const [bannerFile, setBannerFile] = useState<string>();
    const [categoryFile, setCategoryFile] = useState<string>();
    const [file, setFile] = useState<Blob>();
    const fileUpload = useRef(null);

    useEffect(() => {
        console.log(parentCategory);
    }, [parentCategory]);

    const handleUpload = () => {
        // @ts-ignore
        fileUpload.current.click()
    };
    const bannerUploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setBannerFile(URL.createObjectURL(e.target.files[0]));
    }

    const categoryUploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setCategoryFile(URL.createObjectURL(e.target.files[0]));
    }

    // the function to add an image banner, categories or featured-products to the android app
    const addContent = (values : any) => {
        setSaveLoading(true);
        var createdAt = moment().format("YYYY-MM-DDTh:mm:ss");
        var ID = Array(20).fill("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz")
            .map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');

        if(contentType == "banners"){
            // if we are adding banners then,
            var object = {imageUrl : "", id: ID}
            uploadBytes(ref(storage, `adBanner/${ID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {
                        object.imageUrl = url;
                        Firefetch.SaveTODB("banners", ID, object)
                            .then((result) => {
                                if(result == "success"){
                                    toast({
                                        position: 'top',
                                        title: 'Banner Added!',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    setSaveLoading(false);
                                    contentForm.resetFields();
                                    setBannerFile("");
                                    closeDrawer(false);
                                }

                            });
                    }).catch(() =>{
                })

            });
        } else if(contentType === "categories"){

            //if we are adding a category
            var catObject = {
                id: ID,
                name: values.categoryName,
                imageUrl: "",
                status: "Active",
            };
            uploadBytes(ref(storage, `categories/${ID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {

                        catObject.imageUrl = url;
                        Firefetch.SaveTODB("categories", ID, catObject)
                            .then((result) => {
                                if(result == "success"){
                                    toast({
                                        position: 'top',
                                        title: 'Category Added!',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    setSaveLoading(false);
                                    contentForm.resetFields();
                                    setCategoryFile("");
                                    closeDrawer(false);
                                }

                            });
                    })
            });


        } else if(contentType === "sub-categories"){

            //if we are adding a category
            var subCatObject = {
                id: ID,
                name: values.subCategoryName,
                categoryID: parentCategory,
                imageUrl: "",
                status: "Active",
            };
            uploadBytes(ref(storage, `subCategories/${ID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {

                        subCatObject.imageUrl = url;
                        Firefetch.SaveTODB("subCategories", ID, subCatObject)
                            .then((result) => {
                                if(result == "success"){
                                    toast({
                                        position: 'top',
                                        title: 'subCategory Added!',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    setSaveLoading(false);
                                    contentForm.resetFields();
                                    setCategoryFile("");
                                    closeDrawer(false);
                                }

                            })
                            .catch((error) => {
                                toast({
                                    position: 'top',
                                    title: 'an error occurred!',
                                    status: 'error',
                                    duration: 3000,
                                    isClosable: true,
                                });
                                setSaveLoading(false);
                        })
                    })
            });


        } else if(contentType === "sections"){

            //if we are adding a category
            var sectObject = {
                id: ID,
                name: values.sectionName,
                contentType: values.sectionContentType,
                imageUrl: "",
                status: "Active",
                content:[]
            };
            uploadBytes(ref(storage, `sections/${ID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {

                        sectObject.imageUrl = url;
                        Firefetch.SaveTODB("sections", ID, sectObject)
                            .then((result) => {
                                if(result == "success"){
                                    toast({
                                        position: 'top',
                                        title: 'Section Added!',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    setSaveLoading(false);
                                    contentForm.resetFields();
                                    setCategoryFile("");
                                    closeDrawer(false);
                                }

                            })
                            .catch((error) => {
                                toast({
                                    position: 'top',
                                    title: 'an error occurred!',
                                    status: 'error',
                                    duration: 3000,
                                    isClosable: true,
                                });
                                setSaveLoading(false);
                            })
                    })
            });


        } else if(contentType === "featured_products"){

            //addign a featured_product
            var prodObject = {dateAdded: createdAt, id: ID, productId: values.product};
            Firefetch.SaveTODB("featured_products", ID, prodObject)
                .then((result) => {
                    if(result == "success"){
                        toast({
                            position: 'top',
                            title: 'Product Added!',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                        setSaveLoading(false);
                        contentForm.resetFields();
                        closeDrawer(false);
                    }
                });
        }
    }

    return (
        <>
            <Modal
                closeButton
                aria-labelledby="modal-title"
                open={openDrawer}
                onClose={() => {closeDrawer(false)}}
            >
                <Modal.Header>
                    <Text b size={18}>
                       Add Content: {contentType}
                    </Text>
                </Modal.Header>
                <Modal.Body className="overflow-hidden">
                    <Form
                        className="mt-2"
                        id="contentForm"
                        form={contentForm}
                        layout={"vertical"}
                        onFinish={addContent}
                    >

                        { contentType === "categories" ?
                            <div>
                                <Form.Item
                                    name="categoryIcon"
                                    label={<b>Category Icon</b>}
                                    rules={[
                                        { required: true, message: 'Please select category icon file!' },
                                    ]}
                                >
                                    <Tooltip content={"Add Category Icon"} rounded color="primary">
                                        <input
                                            type="file"
                                            ref={fileUpload}
                                            accept="image/*"
                                            onChange={categoryUploadFile}
                                            style={{ opacity: "0" }}
                                        />
                                        <Image
                                            width={100}
                                            height={"50%"}
                                            src={categoryFile}
                                            className="d-flex align-self-center"
                                            fallbackSrc={holder}
                                            onClick={() => {
                                                handleUpload()
                                            }}
                                            alt='profile'
                                        />
                                    </Tooltip>

                                </Form.Item>
                                <Form.Item
                                    name="categoryName"
                                    label={<b>Category Name</b>}
                                    rules={[
                                        { required: true, message: 'Please input categoryName!' },
                                    ]}
                                >
                                    <Input
                                        clearable
                                        bordered
                                        fullWidth
                                        color="primary"
                                        size="lg"
                                        className="mt-3"
                                        placeholder="category name"
                                        contentLeft={<i className="pi pi-plus"/>}
                                    />
                                </Form.Item>
                            </div>

                            :
                            null
                        }

                        { contentType === "sub-categories" ?
                            <div>
                                <Form.Item
                                    name="sub-categoryIcon"
                                    label={<b>Sub-Category Icon</b>}
                                    rules={[
                                        { required: true, message: 'Please select sub-category icon file!' },
                                    ]}
                                >
                                    <Tooltip content={"Add Sub-Category Icon"} rounded color="primary">
                                        <input
                                            type="file"
                                            ref={fileUpload}
                                            accept="image/*"
                                            onChange={categoryUploadFile}
                                            style={{ opacity: "0" }}
                                        />
                                        <Image
                                            width={100}
                                            height={"50%"}
                                            src={categoryFile}
                                            className="d-flex align-self-center"
                                            fallbackSrc={holder}
                                            onClick={() => {
                                                handleUpload()
                                            }}
                                            alt='profile'
                                        />
                                    </Tooltip>

                                </Form.Item>
                                <Form.Item
                                    name="subCategoryName"
                                    label={<b>Category Name</b>}
                                    rules={[
                                        { required: true, message: 'Please input subCategoryName!' },
                                    ]}
                                >
                                    <Input
                                        clearable
                                        bordered
                                        fullWidth
                                        color="primary"
                                        size="lg"
                                        className="mt-3"
                                        placeholder="sub-category name"
                                        contentLeft={<i className="pi pi-plus"/>}
                                    />
                                </Form.Item>
                            </div>

                            :
                            null
                        }

                        { contentType === "featured_products" ?
                            <Form.Item
                                name="product"
                                label={<b>Featured Product</b>}
                                rules={[
                                    { required: true, message: 'Please select product!' },
                                ]}
                            >
                                <Select placeholder='Select product' className="mt-3">
                                    {products.map((item) => (
                                        <option value={item.productId}>
                                            <div className="d-flex flex-row">
                                                <img alt={item.name} src={item.imageUrl}/>
                                                <b>{item.name}</b>
                                            </div>
                                        </option>
                                    ))}
                                </Select>
                            </Form.Item>
                            :
                            null
                        }
                        { contentType === "banners" ?
                            <Form.Item
                                name="bannerImage"
                                label={<b>Banner Image</b>}
                                rules={[
                                    { required: true, message: 'Please select banner image!' },
                                ]}
                            >
                                <Tooltip content={"Add Banner Image"} rounded color="primary">
                                    <input
                                        type="file"
                                        ref={fileUpload}
                                        accept="image/*"
                                        onChange={bannerUploadFile}
                                        style={{ opacity: "0" }}
                                    />
                                    <Image
                                        width={300}
                                        height={"50%"}
                                        src={bannerFile}
                                        className="d-flex align-self-center"
                                        fallbackSrc={holder}
                                        onClick={() => {
                                            handleUpload()
                                        }}
                                        alt='profile'
                                    />
                                </Tooltip>

                            </Form.Item>
                            :
                            null
                        }

                        { contentType === "sections" ?
                            <div>
                                <Form.Item
                                    name="sectionIcon"
                                    label={<b>Section Icon</b>}
                                    rules={[
                                        { required: true, message: 'Please select icon file!' },
                                    ]}
                                >
                                    <Tooltip content={"Add Section Icon"} rounded color="primary">
                                        <input
                                            type="file"
                                            ref={fileUpload}
                                            accept="image/*"
                                            onChange={categoryUploadFile}
                                            style={{ opacity: "0" }}
                                        />
                                        <Image
                                            width={100}
                                            height={"50%"}
                                            src={categoryFile}
                                            className="d-flex align-self-center"
                                            fallbackSrc={holder}
                                            onClick={() => {
                                                handleUpload()
                                            }}
                                            alt='profile'
                                        />
                                    </Tooltip>

                                </Form.Item>
                                <Form.Item
                                    name="sectionName"
                                    label={<b>Section Name</b>}
                                    rules={[
                                        { required: true, message: 'Please input name!' },
                                    ]}
                                >
                                    <Input
                                        clearable
                                        bordered
                                        fullWidth
                                        color="primary"
                                        size="lg"
                                        className="mt-3"
                                        placeholder="category name"
                                        contentLeft={<i className="pi pi-plus"/>}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="sectionContentType"
                                    label={<b>Section Content</b>}
                                    rules={[
                                        { required: true, message: 'Please select content type!' }
                                    ]}
                                >
                                    <Select placeholder='Select option'>
                                        {["Images", "Products", "Sellers"].map((item) => (
                                            <option value={item}>{item}</option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            :
                            null
                        }

                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button auto type='submit'
                            form="contentForm">
                        {
                            saveLoading ?
                                <Loading type="points-opacity" color="currentColor" size="sm" />
                                :
                                <>SAVE</>
                        }

                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AddContent;
