import React, {useState} from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerOverlay, FormLabel, useToast,
} from "@chakra-ui/react";
import {Form} from "antd";
import {Input} from "@nextui-org/react";
import {getAuth, updateProfile, createUserWithEmailAndPassword, signOut} from "firebase/auth";
import moment from "moment";
import Firefetch from "../../Firebase/Firefetch";
import SecondaryFirebase from "../../Firebase/SecondaryFirebase";
import emailjs from "emailjs-com";


type Props = {
    openDrawer: boolean;
    closeDrawer: (val: boolean) => void;
};

var secondAuth = getAuth(SecondaryFirebase);
const AddAdminUser: React.FC<Props> = ({openDrawer, closeDrawer}) => {
    const toast = useToast();
    const [saveLoading, setSaveLoading] = useState(false);


    const addAdmin = (values : any) => {
        setSaveLoading(true);
        var password = Array(8).fill("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz@#$%^&*()")
            .map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
        createUserWithEmailAndPassword(secondAuth, values.email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                updateProfile(user, {
                    displayName: values.firstname + " " + values.surname,
                });
                var userObject = values;
                userObject.userID = user.uid;
                userObject.userRole = "Admin";
                userObject.dateAdded = moment().format("YYYY-MM-DDTh:mm:ss");
                userObject.userStatus = "Active";

                Firefetch.SaveTODB("users", user.uid, userObject)
                    .then((result) => {
                        signOut(secondAuth);
                        console.log(result);
                        if(result == "success"){
                            toast({
                                position: 'top',
                                title: 'Admin User Added!',
                                description: "An email has been sent to their account with their login credentials",
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            });
                            setSaveLoading(false);
                            emailjs.send("service_v2ya563","template_q1lkr8b", {
                                userEmail: values.email,
                                username: values.firstname + " " + values.surname,
                                userPassword : password,
                                platformURL : "https://shoppedmw-app.firebaseapp.com/"
                            })
                                .then(function(response) {
                                    console.log('SUCCESS!', response.status, response.text);
                                    closeDrawer(false);
                                }, function(error) {
                                    console.log('FAILED...', error);
                                });

                        }

                    });



            })
            .catch((error: any) => {
                // ..
                console.log(error.message);
            });
    }

    return (
        <>
            <Drawer
                isOpen={openDrawer}
                placement='right'
                onClose={() => { closeDrawer(false) }}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Add Admin User
                    </DrawerHeader>

                    <DrawerBody>
                        <Form
                            className="mt-2"
                            id="adminForm"
                            layout={"vertical"}
                            onFinish={addAdmin}
                        >
                            <FormLabel htmlFor='firstname' className="ml-1">Firstname</FormLabel>
                            <Form.Item
                                name="firstname"
                                rules={[
                                    { required: true, message: 'Please input firstname!' },
                                ]}
                            >
                                <Input
                                    type="text"
                                    size="lg"
                                    id="firstname"
                                    aria-label="firstname"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter user's firstname"
                                    className="my-1"
                                />
                            </Form.Item>

                            <FormLabel htmlFor='surname' className="ml-1">Surname</FormLabel>
                            <Form.Item
                                name="surname"
                                rules={[
                                    { required: true, message: 'Please input surname!' }
                                ]}
                            >
                                <Input
                                    type="text"
                                    size="lg"
                                    id="surname"
                                    aria-label="surname"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter user's surname"
                                    className="my-1"
                                />
                            </Form.Item>
                            <FormLabel htmlFor='email' className="ml-1">Email</FormLabel>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input email!' },
                                    {
                                        pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                        message: "Not a valid email address!"}
                                ]}
                            >
                                <Input
                                    type="email"
                                    size="lg"
                                    id="email"
                                    aria-label="email"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter user's email address"
                                    className="my-1"
                                />
                            </Form.Item>


                        </Form>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={() => { closeDrawer(false) }}>
                            Cancel
                        </Button>
                        <Button  type='submit'
                                 isLoading={saveLoading}
                                 form="adminForm"
                                 colorScheme='blue'>Submit</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default AddAdminUser;
