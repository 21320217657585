import React, {useEffect, useState } from 'react';
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Card, Container, Text} from "@nextui-org/react";
import {Layout, Table} from "antd";
import { Input } from "@nextui-org/react";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore} from "firebase/firestore";
import {ColumnsType} from "antd/lib/table";
import moment from 'moment';
import Firebase from "../../Firebase/Firebase";
import Headerbar from "../../navBars/Headerbar";
import Sidebar from "../../navBars/Sidebar";
import {Tag} from "primereact/tag";


interface DataType {
    key: number;
    buyer: string;
    seller: string;
    product: string;
    amount: string,
    status: JSX.Element;
    date: string;
    payment: string;
}
const { Content } = Layout;
const db = getFirestore(Firebase);
const Transactions = () => {

    const columns: ColumnsType<DataType> = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Seller',
            dataIndex: 'seller',
            key: 'seller',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Buyer',
            dataIndex: 'buyer',
            key: 'buyer',
        },
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Payment',
            key: 'payment',
            dataIndex: 'payment'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },

    ];

// @ts-ignore

    const [collapsed, setCollapsed] = useState(false);
    const [snapshot, loading] = useCollection(collection(db, 'orders'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [sellerSnapshot] = useCollection(collection(db, 'sellers'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [buyerSnapshot] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [productSnapshot] = useCollection(collection(db, 'products'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [transArray, setTransArray] = useState<any[]>([]);
    const [dataArray, setDataArray] = useState<DataType[]>([]);
    function numberWithCommas(x: string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    useEffect(() => {
        if(snapshot){
            var tempArray: any[] = [];
            snapshot.docs.map((doc: { data: () => any; }) => {
                tempArray.push(doc.data());
            });
            var sellerTemp: any[] = [];
            var buyerTemp: any[] = [];
            var productTemp: any[] = [];
            sellerSnapshot?.docs.map((doc: { data: () => any; }) => {
                sellerTemp.push(doc.data());
            });
            buyerSnapshot?.docs.map((doc: { data: () => any; }) => {
                buyerTemp.push(doc.data());
            });

            productSnapshot?.docs.map((doc: { data: () => any; }) => {
                productTemp.push(doc.data());
            });

            var emptyArray: DataType[] = [];
            tempArray.map((item, index) => {
                emptyArray.push({
                    key: index + 1,
                    seller: item.sellerName,
                    buyer : item.buyername,
                    amount: `MK ${numberWithCommas(item.totalAmount)}`,
                    product: item.items[0].productName,
                    //product: productTemp.find(x => (x.productId) === item.productId)?.name,
                    status: <Tag className="mx-2"
                                 severity={(item.orderStatus == "pending") ? "warning" : ((item.orderStatus == "completed") ? "success" : "info")}
                                 value={item.orderStatus}></Tag>,

                                 
                    date: moment(item.createdAt, "DD MMM YYYY").format("DD MMM YYYY "),
                    payment : item.paymentMethods
                });
            });

            setDataArray([...emptyArray]);
            setTransArray([...emptyArray]);
        }
    }, [snapshot, sellerSnapshot, buyerSnapshot, productSnapshot]);

    const searchFilter = (searchText : string) => {
        searchText = searchText.toLowerCase();

        var filteredArray = transArray.filter(({seller, buyer, product, status, date}) => {
            seller = seller.toLowerCase();
            buyer = buyer.toLowerCase();
            product = product.toLowerCase();
            status = status.toLowerCase();
            date = date.toLowerCase();

            return seller?.includes(searchText) || buyer?.includes(searchText)
                || product?.includes(searchText) || status?.includes(searchText) || date?.includes(searchText);
        });

        setDataArray([...filteredArray]);
    }


    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>
                        <Container className="mt-2">
                            <MDBRow>
                                <MDBCol>
                                    <Card  className="my-2 p-2 font-JosefinSans overflow-hidden">
                                        <div className="d-flex pt-2 px-2 justify-content-between align-item-center">
                                            <Text h4 size={23}
                                                  css={{ color: "#29327b", fontWeight: "$bold", margin: 10 }}>
                                                Transactions
                                            </Text>
                                            <Input
                                                placeholder="search transactions"
                                                onChange={(e) => { searchFilter(String(e.target.value))}}
                                                className="mx-1"
                                                contentRight={<MDBIcon icon="search"/>}
                                            />
                                        </div>
                                    </Card>
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <Table columns={columns}
                                               dataSource={dataArray} loading={loading}
                                               pagination={{ defaultPageSize: 20, showSizeChanger: false, pageSizeOptions: ['20']}}/>
                                    </Card>
                                </MDBCol>
                            </MDBRow>
                        </Container>
                    </Content>
                </Layout>
            </Layout>

        </>
    )
}

export default Transactions;
