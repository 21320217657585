import React, {useEffect, useState } from 'react';
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Button, Card, Container, Text} from "@nextui-org/react";
import Headerbar from "../../navBars/Headerbar";
import Sidebar from "../../navBars/Sidebar";
import {Layout, Table} from "antd";
import { Input } from "@nextui-org/react";
import AddSeller from '../../Drawers/Seller/AddSeller';
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore} from "firebase/firestore";
import Firebase from "../../Firebase/Firebase";
import {ColumnsType} from "antd/lib/table";
import moment from 'moment';
import {generatePath, useNavigate} from "react-router-dom";


interface DataType {
    key: number;
    name: string;
    phone: string;
    location: string;
    userStatus: string;
    createdAt: string;
    action: JSX.Element;
}
const { Content } = Layout;
const db = getFirestore(Firebase);
const Sellers = () => {

    const columns: ColumnsType<DataType> = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Seller',
            dataIndex: 'name',
            key: 'name',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Status',
            dataIndex: 'userStatus',
            key: 'userStatus',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action'
        },
    ];

// @ts-ignore

    const [collapsed, setCollapsed] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [sellerSnapshot, loading] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const navigate = useNavigate();
    const [sellerArray, setSellerArray] = useState<any[]>([]);
    const [dataArray, setDataArray] = useState<DataType[]>([]);

    useEffect(() => {
        if(sellerSnapshot){
            var tempArray: any[] = [];
            sellerSnapshot.docs.map((doc: { data: () => any; }) => {

                if(doc.data().type === "Seller"){
                    tempArray.push(doc.data());
                }
                //tempArray.push(doc.data());
            });
            var emptyArray: DataType[] = [];
            tempArray.map((item, index) => {
                emptyArray.push({
                    key: index+1,
                    name: item.fullName,
                    phone: item.phoneNumberAuth,
                    location: item.location,
                    userStatus: item.userStatus,
                    createdAt: moment(item.createdAt, "DD MMM YYYY").format("DD/MMM/YYYY"),
                    action : <Button size="xs" onClick={() => {
                        navigate(generatePath('/shops/:id', {id: item.Uid}));
                    }} rounded color="primary" icon={<MDBIcon icon="eye"/>}/>
                });
            });

            setDataArray([...emptyArray]);
            setSellerArray([...emptyArray]);
        }
    }, [sellerSnapshot]);

    const searchFilter = (searchText : string) => {
        searchText = searchText.toLowerCase();

        var filteredArray = sellerArray.filter(({name, location, userStatus}) => {
            name = name.toLowerCase();
            location = location.toLowerCase();
            userStatus = userStatus.toLowerCase();

            return name?.includes(searchText) || location?.includes(searchText) || userStatus?.includes(searchText);
        });

        setDataArray([...filteredArray]);
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>
                        <Container className="mt-2">
                            <AddSeller openDrawer={addModal} closeDrawer={setAddModal}/>
                            <MDBRow>
                                <MDBCol>
                                    <Card  className="my-2 p-2 font-JosefinSans overflow-hidden">
                                        <div className="d-flex pt-2 px-2 justify-content-between align-item-center">
                                            <Text h4 size={23}
                                                  css={{ color: "#29327b", fontWeight: "$bold", margin: 10 }}>
                                                Sellers
                                            </Text>
                                            <div className="d-flex flex-row">
                                                <Input
                                                    placeholder="search sellers"
                                                    onChange={(e) => { searchFilter(String(e.target.value))}}
                                                    className="mx-1 mr-1"
                                                    contentRight={<MDBIcon icon="search"/>}
                                                />
                                                <Button color="primary" className="mx-1 ml-2" auto shadow onPress={() => {
                                                    setAddModal(true);
                                                }}>
                                                    Add
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <Table columns={columns}
                                               dataSource={dataArray} loading={loading}
                                               pagination={{ defaultPageSize: 20, showSizeChanger: false, pageSizeOptions: ['20']}}/>
                                    </Card>
                                </MDBCol>
                            </MDBRow>
                        </Container>
                    </Content>
                </Layout>
            </Layout>

        </>
    )
}

export default Sellers;
