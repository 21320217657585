import React, {useState} from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerOverlay, FormLabel, useToast,
} from "@chakra-ui/react";
import {Form} from "antd";
import {Input} from "@nextui-org/react";
import {updateEmail, getAuth, signInWithEmailAndPassword} from "firebase/auth";
import Firebase from "../../Firebase/Firebase";
import {doc, getFirestore, updateDoc} from "firebase/firestore";


type Props = {
    changeEmail: boolean;
    closeEmail: (val: boolean) => void;
};

const firebaseAuth = getAuth(Firebase);
const db = getFirestore(Firebase);
const ChangeEmail: React.FC<Props> = ({changeEmail, closeEmail}) => {

    const toast = useToast();
    const [changeLoading, setChangeLoading] = useState(false);

    const handleEmailChange = (values : any) => {
        setChangeLoading(true);
        signInWithEmailAndPassword(firebaseAuth, values.oldEmail, values.password).then((userCredential) => {
            //do something here
            const user = userCredential.user;
            if (firebaseAuth.currentUser) {
                updateEmail(firebaseAuth.currentUser, values.newEmail).then(() => {

                    const docRef = doc(db, "users", user.uid);
                    updateDoc(docRef, {
                        email : values.newEmail
                    }).then(() => {
                        setChangeLoading(false);
                        closeEmail(false);
                        toast({
                            position: 'top',
                            title: 'Email changed!',
                            description: "Your email has been changed. Remember to use it the next time you login",
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                    }).catch(() => {
                        setChangeLoading(false);
                    });

                }).catch((error) => {
                    setChangeLoading(false);
                    toast({
                        position: 'top',
                        title: 'Failure!',
                        description: "An error occurred : " + error.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
            }


        }).catch((error) => {
            setChangeLoading(false);
            toast({
                title: 'Failure!',
                description: "An error occurred : " + error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        });
    }
    return (
        <>
            <Drawer
                isOpen={changeEmail}
                placement='right'
                onClose={() => { closeEmail(false) }}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Change Email Address
                    </DrawerHeader>

                    <DrawerBody>
                        <Form
                            onFinish={handleEmailChange}
                            className="mt-4"
                            id="emailForm"
                            layout={"vertical"}
                        >
                            <FormLabel htmlFor='oldEmail' className="ml-1">Old email</FormLabel>
                            <Form.Item
                                name="oldEmail"
                                rules={[
                                    { required: true, message: 'Please input email!' },
                                    {
                                        pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                        message: "Not a valid email address!"}
                                ]}
                            >
                                <Input
                                    type="email"
                                    size="lg"
                                    id="oldEmail"
                                    aria-label="email"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter your current email address"
                                    className="my-1"
                                />
                            </Form.Item>

                            <FormLabel htmlFor='password' className="ml-1">Current Password</FormLabel>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please enter a password!' },]}
                            >
                                <Input.Password
                                    size="lg"
                                    aria-label="password"
                                    id="password"
                                    placeholder="Enter your password"
                                    width={"100%"}
                                    onChange={() => {}}
                                    className="w-100 my-1"
                                />
                            </Form.Item>

                            <FormLabel htmlFor='newEmail' className="ml-1">New Email</FormLabel>
                            <Form.Item
                                name="newEmail"
                                rules={[
                                    { required: true, message: 'Please input email!' },
                                    {
                                        pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                        message: "Not a valid email address!"}
                                ]}
                            >
                                <Input
                                    type="email"
                                    size="lg"
                                    id="newEmail"
                                    aria-label="email"
                                    width={"100%"}
                                    onChange={() => {}}
                                    placeholder="Enter the new email address"
                                    className="my-1"
                                />
                            </Form.Item>

                        </Form>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={() => { closeEmail(false) }}>
                            Cancel
                        </Button>
                        <Button  type='submit'
                                 isLoading={changeLoading}
                                 form="emailForm"
                                 colorScheme='blue'>Submit</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ChangeEmail;
