import React, {useEffect, useRef, useState} from 'react';
import {Form} from "antd";
import {Button, Input, Loading, Modal} from "@nextui-org/react";
import {Image, useToast} from "@chakra-ui/react";
import holder from "../../images/plainHolder.jpg";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Firebase from "../../Firebase/Firebase";
import Firefetch from "../../Firebase/Firefetch";


type Props = {
    openModal: boolean;
    closeModal: (val: boolean) => void;
    section: any;
};

var storage = getStorage(Firebase);
const EditSection: React.FC<Props> = ({openModal, closeModal, section}) => {

    const [file, setFile] = useState<Blob>();
    const fileUpload = useRef(null);
    const [sectionFile, setSectionFile] = useState<string>( section?.imageUrl );
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast();

    useEffect(() => {
        //setSectionFile( section?.imageUrl );
    }, [section]);

    const categoryUploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setSectionFile(URL.createObjectURL(e.target.files[0]));
    }

    const handleUpload = () => {
        // @ts-ignore
        fileUpload.current.click()
    };

    const editSection = (values: any) => {
        setLoading(true);

        var ID = section?.id;
        var sectObject = {
            name: values.sectionName,
            imageUrl: section?.imageUrl,
        };

        Firefetch.UpdateInDB("sections", ID, sectObject)
            .then((result) => {
                if(result == "success"){
                    toast({
                        position: 'top',
                        title: 'Section edited!',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setLoading(false);
                    closeModal(false);
                }

            });

        if(file != null){
            uploadBytes(ref(storage, `sections/${ID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {
                        var picObj = { imageUrl: url };
                        Firefetch.UpdateInDB("sections", ID, picObj)

                    })
            });
        }

    }

    return (
        <div>
            <Modal
                closeButton
                aria-labelledby="edit-category"
                open={openModal}
                onClose={() => {closeModal(false)}}
            >
                <h1>Edit Section</h1>
                <Form
                    layout={"vertical"}
                    onFinish={editSection}
                    initialValues={
                        {
                            sectionName: section?.name,
                        }
                    }
                >
                    <div className="p-3">
                        <Form.Item
                            name="sectionIcon"
                            label={<b>Section Icon</b>}
                            rules={[
                                { required: false, message: 'Please select icon file!' },
                            ]}
                        >

                            <input
                                type="file"
                                ref={fileUpload}
                                accept="image/*"
                                onChange={categoryUploadFile}
                                style={{ opacity: "0" }}
                            />
                            <Image
                                width={100}
                                height={"50%"}
                                src={sectionFile == null ? section?.imageUrl : sectionFile}
                                className="d-flex align-self-center"
                                fallbackSrc={holder}
                                onClick={() => {
                                    handleUpload()
                                }}
                                alt='profile'
                            />


                        </Form.Item>

                        <Form.Item
                            name="sectionName"
                            label={<b>Section Name</b>}
                            rules={[
                                { required: true, message: 'Please input name!' },
                            ]}
                        >
                            <Input
                                clearable
                                bordered
                                fullWidth
                                color="primary"
                                size="lg"
                                className="mt-3"
                                placeholder="category name"
                                contentLeft={<i className="pi pi-plus"/>}
                            />
                        </Form.Item>

                        <Button auto disabled={loading} type="submit" className=" my-4">

                            {
                                loading ? <Loading type="spinner" color="currentColor" size="sm" /> : <>Edit</>
                            }
                        </Button>
                    </div>
                </Form>
            </Modal>

        </div>
    )
}

export default EditSection;
