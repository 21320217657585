import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import {generatePath, useNavigate} from "react-router-dom";

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    action: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'seller',
    },
    {
        title: 'Orders',
        dataIndex: 'orders',
        key: 'orders',
    },
    {
        title: 'Units Sold',
        key: 'sold',
        dataIndex: 'sold'
    },
];

type Props = {
    data: any[];
};

const TopSellers: React.FC<Props> = ({data}) => {
    const navigate = useNavigate();

    return (
        <>
            <Table columns={columns}
                   dataSource={data.sort((a,b) => b.orders - a.orders)}
                   onRow={(record, rowIndex) => {
                       return {
                           onClick: event => {
                               navigate(
                                   generatePath('/shops/:id', {id: record.sellerId})
                               )
                           }, // click row
                       };
                   }}
                   pagination={{ defaultPageSize: 4, showSizeChanger: false, pageSizeOptions: ['20']}}/>
        </>
    )
}

export default TopSellers;
