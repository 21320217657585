import React, {Fragment} from "react";
import {
    Route, Routes,
} from "react-router-dom";
import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import Home from "./Pages/Home/Home";
import Profile from "./Pages/Profile";
import Sellers from "./Pages/Seller/Sellers";
import Transactions from "./Pages/Transactions/Transactions";
import Products from "./Pages/Products/Products";
import Buyers from "./Pages/Buyers/Buyers";
import SellerDetails from "./Pages/Seller/SellerDetails";
import ProductDetails from "./Pages/Products/ProductDetails";
import BuyerDetails from "./Pages/Buyers/BuyerDetails";
import AppContent from "./Pages/AppContent/AppContent";

const Navigation = () => {

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<Login/>} />
                <Route path="/forgot" element={<ForgotPassword/>} />
                <Route path="/home" element={<Home/>} />
                <Route path="/profile" element={<Profile/>} />
                <Route path="/shops" element={<Sellers/>} />
                <Route path="/shops/:id" element={<SellerDetails/>} />
                <Route path="/transactions" element={<Transactions/>} />
                <Route path="/products" element={<Products/>} />
                <Route path="/products/:id" element={<ProductDetails/>} />
                <Route path="/buyers" element={<Buyers/>} />
                <Route path="/buyers/:id" element={<BuyerDetails/>} />
                <Route path="/content" element={<AppContent/>} />
            </Routes>

        </Fragment>
    );

}

export default Navigation;
