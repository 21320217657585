import React, {useEffect, useState} from "react";
import Headerbar from "../../navBars/Headerbar";
import {Avatar, Layout, List} from "antd";
import Sidebar from "../../navBars/Sidebar";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Button, Card, Spacer, Text, Tooltip} from "@nextui-org/react";
import {useLocation, useParams} from "react-router-dom";
import {
    Alert, useToast
} from '@chakra-ui/react'
import {useCollection, useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, getFirestore, query, updateDoc, where} from "firebase/firestore";
import Firebase from "../../Firebase/Firebase";
import TransactionTable from "./TransactionTable";
import TransactionChart from "../Buyers/TransactionChart";
import {Tag} from "primereact/tag";
import {Rating} from "primereact/rating";


const moment = require("moment");
const { Content } = Layout;
const db = getFirestore(Firebase);
const ProductDetails = () => {

    const { id } = useParams();
    const toast = useToast();
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const [product, setProduct] = useState<any>();
    const [reviewArray, setReviewArray] = useState<any[]>([]);
    const [sellerArray, setSellerArray] = useState<any[]>([]);
    const [transactionsArray, setTransactionsArray] = useState<any[]>([]);
    const [logsArray, setLogsArray] = useState<any[]>([]);
    const [ordersArray, setOrdersArray] = useState<any[]>([]);
    const [userArray, setUserArray] = useState<any[]>([]);
    const [value] = useDocumentData(
        doc(db, "products", `${id?.trim()}`),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [reviewSnapshot] = useCollection(query(collection(db, 'reviews'), where("objectId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [sellerSnapshot] = useCollection(query(collection(db, 'users'), where("type", "==", `Seller`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [orderSnapshot] = useCollection(query(collection(db, 'orders'), where("productId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [logSnapshot] = useCollection(query(collection(db, 'logs'), where("objectID", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [buyerSnapshot] = useCollection(query(collection(db, 'users'), where("type", "==", `buyer`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );


    useEffect(() => {
        if(value) {
            setProduct(value);
        }
        var logs: any[] = [];
        var sellArray: any[] = [];
        var tempArray: any[] = [];
        var orders: any[] = [];
        var buyerArray: any[] = [];
        if(buyerSnapshot){
            buyerSnapshot.docs.map((doc: { data: () => any; }) => {
                buyerArray.push(doc.data());
            });
            setUserArray([...buyerArray]);
        }

        if(sellerSnapshot){
            sellerSnapshot.docs.map((doc: { data: () => any; }) => {
                sellArray.push(doc.data());
            });
            setSellerArray([...sellArray]);
        }
        if(reviewSnapshot){
            reviewSnapshot.docs.map((doc: { data: () => any; }) => {
                tempArray.push(doc.data());
            });

            setReviewArray([...tempArray]);
        }
        if(orderSnapshot){
            orderSnapshot.docs.map((doc: { data: () => any; }) => {
                orders.push(doc.data());
            });
            var orderArray: any[] = [];
            orders.map((item) => {
                orderArray.push({
                    seller: sellArray.find(x => (x.Uid) === item.sellerId)?.fullName,
                    amount: `MK ${numberWithCommas(item.totalAmount)}`,
                    buyer: buyerArray.find(x => (x.Uid) === item.buyerId)?.fullName,
                    status: <Tag className="mx-2"
                                 severity={(item.orderStatus == "pending") ? "warning" : ((item.orderStatus == "completed") ? "success" : "info")}
                                 value={item.orderStatus}></Tag>,
                    date: moment(item.createdAt, "YYYY-MM-DDTh:mm:ss").format("DD MMM YYYY HH:mm"),
                    payment : item.paymentMethods
                });
            });

            setOrdersArray([...orders]);
            setTransactionsArray([...orderArray]);
        }

        if(logSnapshot){
            logSnapshot.docs.map((doc: { data: () => any; }) => {
                logs.push(doc.data());
            });
            setLogsArray([...logs]);
        }

    }, [id, value, location, reviewSnapshot, buyerSnapshot, orderSnapshot, logSnapshot, sellerSnapshot]);

    function numberWithCommas(x: string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const changeStatus = (status: string) => {
        const docRef = doc(db, "products", `${id?.trim()}`);
        updateDoc(docRef, {
            productStatus: status
        }).then(() => {
            toast({
                position: 'top',
                title: 'Product status changed!',
                description: "Product status changed successfully!",
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        })
    }
    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>

                        <div className="mt-4 mx-4">
                            <MDBRow>
                                <MDBCol md="5">
                                    <div className="d-flex flex-row my-1">
                                        <Card css={{ bg: "$black", w: "100%" }}>
                                            <Card.Image
                                                src={product?.images[0]}
                                                width="100%"
                                                height={340}
                                                objectFit="cover"
                                                alt="Card image background"
                                            />
                                        </Card>
                                        <Card className="font-JosefinSans"
                                              style={{ overflow: 'hidden'}} >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                                <b>Product Details</b>
                                                <div className="d-flex flex-row">
                                                    <Tooltip content={"Change status"} rounded color="primary">
                                                        <Button shadow rounded size="xs" className="mx-1" color="primary" onClick={() => {
                                                            var status: string;
                                                            if(product.productStatus === "active" || product.productStatus === "Active"){
                                                                status = "disabled";
                                                            } else {
                                                                status = "Active";
                                                            }

                                                            changeStatus(status);
                                                        }}>
                                                            <i className="pi pi-replay"/>
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            <div className="px-2 m-1">
                                                <Alert status='info' variant='left-accent' className="my-1">Name: {product?.name}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Price: MK{numberWithCommas(String(product?.price))}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Stock: {product?.quantity}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Category: {product?.category}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Location: {product?.district}</Alert>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="mt-3">
                                        <Card className="font-JosefinSans"
                                              style={{ overflow: 'hidden'}} >
                                            <div className="p-2 m-1">
                                                <Alert status='info' variant='left-accent' className="my-1">Status:
                                                    <Tag className="mx-2" severity={(product?.productStatus == "Active") || (product?.productStatus == "active") ? "success" : "warning"}
                                                         value={product?.productStatus}></Tag>
                                                </Alert>
                                                <Alert status='info' variant='top-accent'
                                                       height='70px'
                                                       className="my-1">{product?.description}</Alert>
                                                <Alert status='info' variant='top-accent' className="my-1">Condition: {product?.condition}</Alert>
                                                <div className="d-flex flex-row">
                                                    <Alert status='info' variant='subtle' className="my-1 mr-1">Seller: {sellerArray.find(x => (x.sellerID) === product?.sellerId)?.fullName}</Alert>
                                                    <Alert status='info' variant='subtle' className="my-1 ml-1">DateAdded: {moment(product?.dateAdded, "YYYY-MM-DDTHH:mm:ss")
                                                        .format("YYYY MMM DD HH:MM")}</Alert>
                                                </div>

                                            </div>
                                        </Card>
                                    </div>

                                </MDBCol>
                                <MDBCol md="7">
                                    <div className="d-flex flex-row h-100 my-1">
                                        <Card className="font-JosefinSans"
                                              style={{ overflow: 'hidden'}} >
                                            <b className="mt-3 ml-3">Transactions</b>
                                            <Card.Body>
                                                <TransactionTable data={transactionsArray}/>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                </MDBCol>
                            </MDBRow>

                            <Spacer y={1}/>
                            <MDBRow>
                                <MDBCol md="8">
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <Card.Header>
                                            <Text b h5 size={17} className="m-2">Product Activity (Past Six Months)</Text>
                                        </Card.Header>
                                        <Card.Body>
                                            <TransactionChart logs={logsArray} orders={ordersArray}/>
                                        </Card.Body>
                                    </Card>

                                </MDBCol>
                                <MDBCol md="4">
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <b className="mt-3 ml-3">Reviews</b>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    onChange: page => {
                                                        console.log(page);
                                                    },
                                                    pageSize: 4,
                                                }}
                                                dataSource={reviewArray}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.title}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size="large"
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}>
                                                                    {(userArray.find(x => (x.userID) === item.buyerId)?.fullName)?.charAt(0)}
                                                                </Avatar>
                                                            }
                                                            title={
                                                                <a href={item.href}>
                                                                    {
                                                                        userArray.find(x => (x.userID) === item.buyerId)?.fullName
                                                                    }
                                                                </a>
                                                            }
                                                            description={
                                                                <div className="d-flex flex-row justify-content-between">
                                                                    <p>
                                                                        {moment(item.createdAt).format("YYYY MMM DD HH:mm")}
                                                                    </p>
                                                                    <p>
                                                                        <Rating value={item.rating} readOnly stars={5} cancel={false} />
                                                                    </p>
                                                                </div>


                                                        }
                                                        />
                                                        {item.description}
                                                    </List.Item>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>

                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default ProductDetails;
