import { initializeApp } from 'firebase/app';

var firebaseConfig = {



    apiKey: "AIzaSyB8_-cTGVbCqGfhcQfHP34u1OFH1bDW7fg",
    authDomain: "shoppedmw-app.firebaseapp.com",
    databaseURL: "https://shoppedmw-app-default-rtdb.firebaseio.com",
    projectId: "shoppedmw-app",
    storageBucket: "shoppedmw-app.appspot.com",
    messagingSenderId: "671339211471",
    appId: "1:671339211471:web:bbde9409aeb9b3f174a5ba",
    measurementId: "G-2K02J1FJXF",
    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
// Initialize Firebase
const SecondaryFirebase = initializeApp(firebaseConfig, 'secondary');
export default SecondaryFirebase;
