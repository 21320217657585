import React, {useState} from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerOverlay, FormLabel, useToast,
} from "@chakra-ui/react";
import {Form} from "antd";
import {Input} from "@nextui-org/react";
import {updatePassword, getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";
import Firebase from "../../Firebase/Firebase";
import {useNavigate} from "react-router-dom";


type Props = {
    changePassword: boolean;
    closePassword: (val: boolean) => void;
};

const firebaseAuth = getAuth(Firebase);
const ChangePassword: React.FC<Props> = ({changePassword, closePassword}) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [changeLoading, setChangeLoading] = useState(false);


    const handlePasswordChange = (values : any) => {
        setChangeLoading(true);
        signInWithEmailAndPassword(firebaseAuth, values.email, values.oldPassword).then(() => {
            if (firebaseAuth.currentUser) {
                updatePassword(firebaseAuth.currentUser, values.newPassword)
                    .then(() => {
                    setChangeLoading(false);
                        toast({
                            position: 'top',
                            title: 'Success!',
                            description: "Password has been successfully changed. You need to login again!",
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                }).then(() => {
                    signOut(firebaseAuth).then(() => {
                        navigate("/");
                    }).catch(()=>{
                        toast({
                            position: 'top',
                            title: 'Logout Error',
                            description: "Unable to logout an error occurred",
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                        })
                    });

                }).catch((error) => {
                    setChangeLoading(false);
                    toast({
                        title: 'Failure!',
                        description: "An error occurred : " + error.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    });
                });
            }


        }).catch((error) => {
            setChangeLoading(false);
            toast({
                title: 'Failure!',
                description: "An error occurred : " + error.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        });

    }
    return (
        <>
            <Drawer
                isOpen={changePassword}
                placement='right'
                onClose={() => { closePassword(false) }}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Change Password
                    </DrawerHeader>

                    <DrawerBody>
                        <Form
                            onFinish={handlePasswordChange}
                            className="mt-4"
                            id="emailForm"
                            layout={"vertical"}
                        >
                            <FormLabel htmlFor='email' className="ml-1">Your email</FormLabel>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: 'Please input email!' },
                                    {
                                        pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                        message: "Not a valid email address!"}
                                ]}
                            >
                                <Input
                                    type="email"
                                    size="lg"
                                    id="email"
                                    aria-label="email"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter your current email address"
                                    className="my-1"
                                />
                            </Form.Item>

                            <FormLabel htmlFor='oldPassword' className="ml-1">Current Password</FormLabel>
                            <Form.Item
                                name="oldPassword"
                                rules={[{ required: true, message: 'Please enter a password!' },]}
                            >
                                <Input.Password
                                    size="lg"
                                    aria-label="password"
                                    id="oldPassword"
                                    placeholder="Enter your old password"
                                    width={"100%"}
                                    onChange={() => {}}
                                    className="w-100 my-1"
                                />
                            </Form.Item>

                            <FormLabel htmlFor='newPassword' className="ml-1">New Password</FormLabel>
                            <Form.Item
                                name="newPassword"
                                rules={[{ required: true, message: 'Please enter a password!' },]}
                            >
                                <Input.Password
                                    size="lg"
                                    aria-label="password"
                                    id="newPassword"
                                    placeholder="Enter your new password"
                                    width={"100%"}
                                    onChange={() => {}}
                                    className="w-100 my-1"
                                />
                            </Form.Item>

                            <FormLabel htmlFor='newRepeatPassword' className="ml-1">Repeat New Password</FormLabel>
                            <Form.Item
                                name="newRepeatPassword"
                                rules={[
                                    { required: true, message: 'Please enter a password!' },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (getFieldValue('newPassword').toLowerCase()
                                                !==  getFieldValue('newRepeatPassword').toLowerCase()) {
                                                return Promise.reject('Passwords do not to match!');
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <Input.Password
                                    size="lg"
                                    aria-label="password"
                                    id="newRepeatPassword"
                                    placeholder="Repeat your new password"
                                    width={"100%"}
                                    onChange={() => {}}
                                    className="w-100 my-1"
                                />
                            </Form.Item>

                        </Form>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={() => { closePassword(false) }}>
                            Cancel
                        </Button>
                        <Button  type='submit'
                                 isLoading={changeLoading}
                                 form="emailForm"
                                 colorScheme='blue'>Submit</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ChangePassword;
