import React, {useEffect, useState} from 'react';
import {Layout} from "antd";
import MenuOptions from "./MenuOptions";
import {Collapse} from "@nextui-org/react";


const { Sider } = Layout;
type Props = {
    collapsed: boolean;
};

const Sidebar: React.FC<Props> = ({collapsed}) => {

    const [collapse, setCollapsed] = useState(false);
    const [smallView, setSmallView] = useState<boolean>();
    useEffect(() => {
        setCollapsed(collapsed);

        toggle();
        // returns true when window is <= 700px
        mq.addListener(toggle);

        // unmount cleanup handler
        return () => mq.removeListener(toggle);
    }, [collapsed]);

    const mq = window.matchMedia('(max-width: 550px)');


    const toggle = () => {
        if (mq.matches) {
            setCollapsed(true);
            setSmallView(true);
        } else {
            setCollapsed(!collapse);
            setSmallView(false);
        }
    }


    return (
        <>

            {!smallView ?

                <Sider
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "sticky",
                        top: 15,
                        left: 0,
                        borderRadius: "15px"
                    }}
                    theme={"light"} trigger={null}
                    width={250}
                    className="my-2 mx-2 p-3"
                    collapsible collapsed={collapse} onCollapse={()=>{setCollapsed(collapse)}}>
                    <div>
                        <MenuOptions collapsed={collapsed}/>
                    </div>
                </Sider>
                :

                <Collapse title={<p className="mx-2 font-weight-bolder">Menu</p>}>
                    <div>
                        <MenuOptions collapsed={collapsed}/>
                    </div>
                </Collapse>
            }
        </>

    )
}

export default Sidebar;
