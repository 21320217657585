import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent, DrawerFooter,
    DrawerHeader,
    DrawerOverlay, useToast,
} from "@chakra-ui/react";
import {Form} from "antd";
import {Input, Tooltip} from "@nextui-org/react";
import Firefetch from "../../Firebase/Firefetch";
import { Select } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import holder from '../../images/holder.jpg';
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Firebase from "../../Firebase/Firebase";


type Props = {
    seller: any,
    openDrawer: boolean;
    closeDrawer: (val: boolean) => void;
};

var storage = getStorage(Firebase);
const EditSeller: React.FC<Props> = ({openDrawer, closeDrawer, seller}) => {
    const regEx = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g;
    const toast = useToast();
    const [sellerForm] = Form.useForm();
    const [saveLoading, setSaveLoading] = useState(false);
    const [imageFile, setImageFile] = useState<string>();
    const [file, setFile] = useState<Blob>();
    const fileUpload = useRef(null);

    const handleUpload = () => {
        // @ts-ignore
        fileUpload.current.click()
    };
    const uploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setImageFile(URL.createObjectURL(e.target.files[0]));
    }

    useEffect(() => {
        if(seller){

        }
    }, [seller]);

    const editSeller = (values : any) => {

        var object = values;

        var sellerID = seller?.sellerID;
        Object.entries(object).forEach(([key, value]) => {
            if(value === undefined){
                object[key] = "";
            }
        });

        Firefetch.UpdateInDB("sellers", sellerID, object)
            .then((result) => {
                if(result == "success"){
                    toast({
                        position: 'top',
                        title: 'Seller Edited!',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setSaveLoading(false);
                    sellerForm.resetFields();
                    closeDrawer(false);

                }

            });

        if(file != null){
            uploadBytes(ref(storage, `sellers/${sellerID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {

                        var picObj = {imageUrl: url};
                        Firefetch.UpdateInDB("sellers", sellerID, picObj)

                    }).catch(() =>{
                    console.log('Error downloading profile picture');
                })

            });
        }

    }

    return (
        <>
            <input
                type="file"
                ref={fileUpload}
                accept="image/*"
                onChange={uploadFile}
                style={{ opacity: "0" }}
            />
            <Drawer
                isOpen={openDrawer}
                placement='right'
                onClose={() => { closeDrawer(false); }}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                        Edit Seller
                    </DrawerHeader>

                    <DrawerBody>
                        <Form
                            className="mt-2"
                            id="sellerForm"
                            form={sellerForm}
                            layout={"vertical"}
                            onFinish={editSeller}
                            initialValues={{
                                fullName: seller?.fullName,
                                email: seller?.email,
                                phoneNumber: seller?.phoneNumber,
                                location: seller?.location,
                                paymentMethod: seller?.paymentMethod,
                                sellerStatus: seller?.sellerStatus
                            }}
                        >
                            <Form.Item
                                name="image"
                            >
                                <div
                                    className="w-100 d-flex justify-content-center"
                                >
                                    <Tooltip content={"seller image"} rounded color="primary">
                                        <Image
                                            borderRadius='full'
                                            boxSize='150px'
                                            src={imageFile == null ? seller?.imageUrl : imageFile}
                                            className="d-flex align-self-center"
                                            fallbackSrc={holder}
                                            onClick={() => {
                                                handleUpload()
                                            }}
                                            alt='profile'
                                        />
                                    </Tooltip>

                                </div>

                            </Form.Item>
                            <Form.Item
                                name="fullName"
                                label={<b>FullName</b>}
                                rules={[
                                    { required: true, message: 'Please input fullName!' },
                                ]}
                            >
                                <Input
                                    type="text"
                                    size="lg"
                                    id="fullName"
                                    aria-label="fullName"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter seller's fullName"
                                    className="my-1"
                                />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label={<b>Email</b>}
                                rules={[
                                    {
                                        pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                        message: "Not a valid email address!"}
                                ]}
                            >
                                <Input
                                    type="email"
                                    size="lg"
                                    id="email"
                                    aria-label="email"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter seller's email address"
                                    className="my-1"
                                />
                            </Form.Item>

                            <Form.Item
                                name="phoneNumber"
                                label={<b>Phone Number</b>}
                                rules={[
                                    { required: true, message: 'Please input phone!' },
                                    {
                                        pattern: new RegExp(regEx),
                                        message: "Not a valid number!"
                                    },
                                    {
                                        max: 13,
                                        message: "A number cannot exceed 12 characters"
                                    }
                                ]}
                            >
                                <Input
                                    type="text"
                                    size="lg"
                                    id="phone"
                                    aria-label="phone"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter seller's phone"
                                    className="my-1"
                                />
                            </Form.Item>

                            <Form.Item
                                name="location"
                                label={<b>Location (district)</b>}
                                rules={[
                                    { required: true, message: 'Please input location!' }
                                ]}
                            >
                                <Input
                                    type="text"
                                    size="lg"
                                    id="location"
                                    aria-label="location"
                                    width={"100%"}
                                    onChange={()=>{}}
                                    placeholder="Enter seller's location"
                                    className="my-1"
                                />
                            </Form.Item>

                            <Form.Item
                                name="paymentMethod"
                                label={<b>Preferred Payment Method</b>}
                                rules={[
                                    { required: true, message: 'Please select paymentMethod!' }
                                ]}
                            >
                                <Select placeholder='Select option'>
                                    {["Airtel Money", "TNM Mpamba", "Mo626", "KakuPay"].map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="sellerStatus"
                                label={<b>Seller Status</b>}
                                rules={[
                                    { required: true, message: 'Please select seller status!' }
                                ]}
                            >
                                <Select placeholder='Select option'>
                                    {["Pending", "Active", "Suspended"].map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </Select>
                            </Form.Item>


                        </Form>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth='1px'>
                        <Button variant='outline' mr={3} onClick={() => { closeDrawer(false) }}>
                            Cancel
                        </Button>
                        <Button  type='submit'
                                 isLoading={saveLoading}
                                 form="sellerForm"
                                 colorScheme='blue'>Edit</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default EditSeller;
