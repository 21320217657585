import React, {useEffect, useState} from "react";
import {Card, Input, Button, Link, Loading} from "@nextui-org/react";
import {MDBContainer, MDBRow} from "mdbreact";
import { MDBCardBody, MDBCol } from 'mdbreact';
import shopped from "../images/shopped.png"
import {Form} from "antd";
import Firebase from "../Firebase/Firebase"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import {Message} from "primereact/message";
import {collection, doc, getDoc, getFirestore} from "firebase/firestore";
import {useToast} from "@chakra-ui/react";
import {useCollection} from "react-firebase-hooks/firestore";


const firebaseAuth = getAuth(Firebase);
const db = getFirestore(Firebase);
const Login = () => {

    const navigate = useNavigate();
    const [user] = useAuthState(firebaseAuth);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const toast = useToast();
    const [userSnapshot] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    useEffect(() => {
        if(user){
            const docRef = doc(db, "users", user.uid);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const userObject = docSnap.data();
                    if(userObject.userRole === "Admin"){
                        setError(false);
                        setLoading(false);
                        navigate("/home");
                    } else {
                        setLoading(false);
                        setError(true);
                        setErrorMessage("You don't have the privileges to access this platform");
                    }
                } else {
                    setLoading(false);
                    toast({
                        title: 'Error: Unable to fetch',
                        description: "Unable to fetch user details. Check your network connection and try again.",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                }

            });

        }
    }, [user]);


    const handleLogin = (values: any) => {
        setLoading(true);
        signInWithEmailAndPassword(firebaseAuth, values.email, values.password).then(() => {
            //do something here
        }).catch((error) => {
            setError(true);
            setLoading(false);
            var words = error.message.split("(")[1];
            var errorM = words.split(")")[0].split("/")[1];

            setErrorMessage(errorM);
            console.error(errorM);
        });
    }

    return (
        <div className="vh-100 bg">

            <MDBContainer >
                <MDBRow justify={"center"} align={"center"} center={true}>
                    <MDBCol md="12" className="my-5">
                        <Card isHoverable className="my-5 p-2" style={{width:"25rem"}}>
                            <MDBCardBody>
                                <img src={shopped} style={{width:"15rem", height:"3.5rem"}} className="rounded mx-auto mb-5 d-block" alt="aligment" />
                                <Form
                                    onFinish={handleLogin}
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Please input email!' },
                                            {
                                                pattern: new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g),
                                                message: "Not a valid email address!"}
                                        ]}
                                    >
                                        <Input
                                            type="email"
                                            size="lg"
                                            aria-label="email"
                                            width={"100%"}
                                            onChange={()=>{}}
                                            placeholder="Your email address"
                                            className="my-1"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Please enter a password!' },]}
                                    >
                                        <Input.Password
                                            size="lg"
                                            aria-label="password"
                                            placeholder="Your password"
                                            width={"100%"}
                                            onChange={()=>{}}
                                            className="w-100 my-1"
                                        />
                                    </Form.Item>

                                    {
                                        error ?
                                            <div style={{width: "100%"}} className="col-12 md:col-3">
                                                <Message severity="error" text={"Unable to login: " + errorMessage} />
                                            </div>
                                            :
                                            null
                                    }
                                    <Button shadow disabled={loading} type="submit" className="w-100 bg-indigo-500 my-4">

                                        {
                                            loading ? <Loading type="spinner" color="currentColor" size="sm" /> : <>Login</>
                                        }
                                    </Button>
                                </Form>
                                <div className="text-center text-black-50 d-flex justify-content-center">
                                    forgot your password? &nbsp;<Link href="/forgot"> click here</Link>
                                </div>
                            </MDBCardBody>
                        </Card>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    )
}

export default Login;
