import React, {useEffect, useState } from 'react';
import {Chart} from "primereact/chart";


type Props = {
    logs: any[];
    orders: any[];
};

type ChartType = {
    labels: string[];
    datasets: any[];
}

const moment = require('moment');
var previousSixMonths = function(){
    let n = 0;
    let arRet = [];

    for(; n < 6; n++)
        arRet.push(moment().subtract(n, 'months').format('MMMM'));
    return(arRet)
}();

const TransactionChart: React.FC<Props> = ({logs, orders})  => {
    var dataArray: ChartType = {
        labels: previousSixMonths,
        datasets: []
    }
    const [basicData, setBasicData] = useState(dataArray);

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    }
                }
            }
        };

        return {
            basicOptions,
            multiAxisOptions
        }
    }
    const { basicOptions } = getLightTheme();

    useEffect(() => {
        var monthNums: number[] = []
        var monthOrderNums: number[] = []
        previousSixMonths.map((month) => {
            var monthLogs = logs.filter(x => moment(x.date, "YYYY-MM-DDTh:mm:ss").format("MMMM") === month);
            var monthOrders = orders.filter(x => moment(x.createdAt, "YYYY-MM-DDTh:mm:ss").format("MMMM") === month);
            monthNums.push(monthLogs?.length);
            monthOrderNums.push(monthOrders?.length);
        });

        var dataArray: any[] = [
            {
                label: 'Transactions',
                data: monthOrderNums,
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            },
            {
                label: 'Views',
                data: monthNums,
                fill: false,
                borderColor: '#FFA726',
                tension: .4
            }
        ];

        setBasicData({
            ...basicData,
            datasets: dataArray
        });

    }, [logs, orders]);

    return (
        <div>
            <div className="card">
                <Chart type="line" data={basicData} options={basicOptions} />
            </div>
        </div>
    )
}

export default TransactionChart;
