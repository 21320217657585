import React, {useRef} from 'react';
import { Toast } from 'primereact/toast';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

type Props = {
    collapsed: boolean;
};

const items: MenuProps['items'] = [
    getItem('Home', 'home', <i  className="pi pi-home" style={{'fontSize': '1.4em'}}/>),
    getItem('Buyers', 'buyers', <i  className="pi pi-users" style={{'fontSize': '1.4em'}}/>),
    getItem('Products', 'products', <i  className="pi pi-shopping-bag" style={{'fontSize': '1.4em'}}/>),
    getItem('Shops', 'shops', <i  className="pi pi-building" style={{'fontSize': '1.4em'}}/>),
    getItem('Transactions', 'transactions', <i  className="pi pi-money-bill" style={{'fontSize': '1.4em'}}/>),
    getItem('Content', 'content', <i  className="pi pi-android" style={{'fontSize': '1.4em'}}/>),
    //getItem('Inquiries', 'inquiries', <i  className="pi pi-inbox" style={{'fontSize': '1.4em'}}/>),
    getItem('Profile', 'profile', <i  className="pi pi-user" style={{'fontSize': '1.4em'}}/>),
];

const MenuOptions: React.FC<Props> = () => {
    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const gotoPath = (path: string) => {
        navigate(`/${path}`);
    };

    const onClick: MenuProps['onClick'] = e => {
        gotoPath(e.key);
    };

    const getKey = (path: string) => {
        var exactPath = path.split('/')[1];

        if(items.some(x => x?.key === exactPath)){
            return exactPath
        }

        return exactPath;
    }

    // @ts-ignore
    return (
        <div>
            <Toast ref={toast}></Toast>
            <Menu
                onClick={onClick}
                style={{borderRight:'none'}}
                selectedKeys={[getKey(location.pathname)]}
                items={items}
            />
        </div>
    );
}

export default MenuOptions;
