import React, {useEffect, useState} from "react";
import {Avatar, Layout, List} from "antd";
import Headerbar from "../../navBars/Headerbar";
import Sidebar from "../../navBars/Sidebar";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Button, Card, Spacer, Text, Tooltip} from "@nextui-org/react";
import {Alert, useToast} from "@chakra-ui/react";
import TransactionChart from "./TransactionChart";
import {useParams} from "react-router-dom";
import {Tag} from "primereact/tag";
import {useCollection, useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, getFirestore, updateDoc, query, where} from "firebase/firestore";
import Firebase from "../../Firebase/Firebase";
import TransactionTable from "./TransactionTable";



const db = getFirestore(Firebase);
const moment = require('moment');
const { Content } = Layout;
const BuyerDetails = () => {
    const { id } = useParams();
    const toast = useToast();
    const [buyer, setBuyer] = useState<any>();
    const [collapsed, setCollapsed] = useState(false);
    const [reviewArray, setReviewArray] = useState<any[]>([]);
    const [sellerArray, setSellerArray] = useState<any[]>([]);
    const [productArray, setProductArray] = useState<any[]>([]);
    const [transactionsArray, setTransactionsArray] = useState<any[]>([]);
    const [logsArray, setLogsArray] = useState<any[]>([]);
    const [ordersArray, setOrdersArray] = useState<any[]>([]);
    const [value] = useDocumentData(
        doc(db, "users", `${id?.trim()}`),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [reviewSnapshot] = useCollection(query(collection(db, 'reviews'), where("buyerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [sellerSnapshot] = useCollection(collection(db, 'sellers'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [prodSnapshot] = useCollection(collection(db, 'products'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [orderSnapshot] = useCollection(query(collection(db, 'orders'), where("buyerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [logSnapshot] = useCollection(query(collection(db, 'logs'), where("buyerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );



    function numberWithCommas(x: string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    useEffect(() => {
        if(value) {
            setBuyer(value);
        }
        var logs: any[] = [];
        var sellArray: any[] = [];
        var prodArray: any[] = [];
        var tempArray: any[] = [];
        var orders: any[] = [];
        if(sellerSnapshot){
            sellerSnapshot.docs.map((doc: { data: () => any; }) => {
                sellArray.push(doc.data());
            });
            setSellerArray([...sellArray]);
        }
        if(prodSnapshot){

            prodSnapshot.docs.map((doc: { data: () => any; }) => {
                prodArray.push(doc.data());
            });
            setProductArray([...prodArray]);
        }
        if(reviewSnapshot){
            reviewSnapshot.docs.map((doc: { data: () => any; }) => {
                tempArray.push(doc.data());
            });

            setReviewArray([...tempArray]);
        }
        if(orderSnapshot){
            orderSnapshot.docs.map((doc: { data: () => any; }) => {
                orders.push(doc.data());
            });
            var orderArray: any[] = [];
            orders.map((item) => {
                orderArray.push({
                    seller: item.sellerName,
                    amount: `MK ${numberWithCommas(item.totalAmount)}`,
                    product: item.items[0].productName,
                    status: <Tag className="mx-2"
                                 severity={(item.orderStatus == "pending") ? "warning" : ((item.orderStatus == "completed") ? "success" : "info")}
                                 value={item.orderStatus}></Tag>,
                    date: moment(item.createdAt, "YYYY-MM-DD ").format("YYYY-MM-DD"),
                    payment : item.paymentMethods
                });
            });

            setOrdersArray([...orders]);
            setTransactionsArray([...orderArray]);
        }

        if(logSnapshot){
            logSnapshot.docs.map((doc: { data: () => any; }) => {
                logs.push(doc.data());
            });
            setLogsArray([...logs]);
        }

    }, [id, value, reviewSnapshot, prodSnapshot, orderSnapshot, logSnapshot, sellerSnapshot]);

    const changeStatus = (status: string) => {
        const docRef = doc(db, "users", `${id?.trim()}`);
        updateDoc(docRef, {
            userStatus: status
        }).then(() => {
            toast({
                position: 'top',
                title: 'User status changed!',
                description: "User status changed successfully!",
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        })
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>

                        <div className="mt-4 mx-4">
                            <MDBRow>
                                <MDBCol md="12">
                                    <div className="d-flex flex-row my-1">
                                        <Card css={{ bg: "$black", w: "100%" }}>
                                            <Card.Image
                                                src={buyer?.userImage}
                                                width="100%"
                                                height={400}
                                                objectFit="cover"
                                                alt="Card image background"
                                            />
                                        </Card>
                                        <Card className="font-JosefinSans my-1"
                                              style={{ overflow: 'hidden' }} >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                                <b>Buyer Details</b>
                                                <div className="d-flex flex-row">

                                                    <Tooltip content={"Change status"} rounded color="primary">
                                                        <Button shadow rounded size="xs" className="mx-1" color="primary" onClick={() => {
                                                            var status: string;
                                                            if(buyer.userStatus === "active" || buyer.userStatus === "Active"){
                                                                status = "disabled";
                                                            } else {
                                                                status = "active";
                                                            }

                                                            changeStatus(status);
                                                        }}>
                                                            <i className="pi pi-replay"/>
                                                        </Button>
                                                    </Tooltip>

                                                </div>
                                            </div>

                                            <div className="px-2 m-1">
                                                <Alert status='info' variant='left-accent' className="my-1">Name: {buyer?.fullName}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Phone: {buyer?.phoneNumberAuth}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Status:
                                                    <Tag className="mx-2" severity={(buyer?.userStatus == "Active") || (buyer?.userStatus == "active") ? "success" : "warning"}
                                                         value={buyer?.userStatus}></Tag>
                                                </Alert>
                                            </div>
                                        </Card>
                                    </div>
                                </MDBCol>
                            </MDBRow>

                            <Spacer y={1}/>
                            <MDBRow>
                                <MDBCol md="8">
                                    <Card className="my-2 font-JosefinSans overflow-hidden" isHoverable>
                                        <Card.Header>
                                            <Text b h5 size={17} className="m-2">User Activity (Past Six Months)</Text>
                                        </Card.Header>
                                        <Card.Body>
                                            <TransactionChart logs={logsArray} orders={ordersArray}/>
                                        </Card.Body>
                                    </Card>

                                    <Card className="my-2 font-JosefinSans overflow-hidden" isHoverable>
                                        <Card.Header>
                                            <Text b h5 size={17} className="m-2">Transactions</Text>
                                        </Card.Header>
                                        <Card.Body>
                                            <TransactionTable data={transactionsArray}/>
                                        </Card.Body>
                                    </Card>

                                </MDBCol>
                                <MDBCol md="4">
                                    <Card className="my-2 font-JosefinSans overflow-hidden" isHoverable isPressable>
                                        <b className="mt-3 ml-3">Reviews By This User ({reviewArray.length})</b>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    onChange: page => {
                                                        console.log(page);
                                                    },
                                                    pageSize: 5,
                                                }}
                                                dataSource={reviewArray}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.title}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size="large"
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}>
                                                                    {
                                                                        item.reviewType === "seller" ?
                                                                        (sellerArray.find(x => (x.sellerID) === item.objectId)?.fullName)?.charAt(0) :
                                                                        (productArray.find(x => (x.productId) === item.objectId)?.name)?.charAt(0)
                                                                    }
                                                                </Avatar>
                                                            }
                                                            title={
                                                                <a href={item.href}>
                                                                    {
                                                                        item.reviewType === "seller" ?
                                                                        sellerArray.find(x => (x.sellerID) === item.objectId)?.fullName :
                                                                            productArray.find(x => (x.productId) === item.objectId)?.name
                                                                    }
                                                                </a>
                                                            }
                                                            description={moment(item.createdAt).format("YYYY MMM DD HH:mm")}
                                                        />
                                                        {item.description}
                                                    </List.Item>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default BuyerDetails;
