import Firebase from "./Firebase";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {doc, getFirestore, updateDoc, setDoc, deleteDoc} from "firebase/firestore";


const db = getFirestore(Firebase);
var auth = getAuth(Firebase);
const moment = require('moment');
class FireFetch {

    SaveTODB(endPoint: string, objectID: string, object: any){

        return setDoc(doc(db, endPoint, objectID), object)
            .then(() => {
                return "success";
            })
            .catch((error:any) => {
                return error.getMessage;
            });

    };

    DeleteFromDB(endPoint:string, objectID:any){

        return updateDoc(doc(db, endPoint, objectID), {"status" : "disabled"})
            .then(() => {
                return "success";
            })
            .catch((error:any) => {
                return error.getMessage;
            });

        /*
        return deleteDoc(doc(db, endPoint, objectID))
            .then(() => {
                return "success";
            })
            .catch((error:any) => {
                return error.getMessage;
            });

         */
    };

    UpdateInDB(endPoint: string, objectID: string, object: any){

        return updateDoc(doc(db, endPoint, objectID), object)
            .then(() => {
                return "success";
            })
            .catch((error:any) => {
                return error.getMessage;
            });

    };

    exportXL(tab: any){
        var table = tab;
        var exportData = table.getExportData();
        console.log(exportData);
        var csv = exportData.dataTable.csv;
        table.export2file(csv.data, csv.mimeType, csv.filename, csv.fileExtension, csv.merges, csv.RTL, csv.sheetname);

    }

    addLog(record: string, recordID: string, actionType: string){
        onAuthStateChanged(auth, (user) => {
            if (user) {
                var timeStamp = moment().format("YYYY-MM-DDTh:mm:ss");
                var objectID = Array(12).fill("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz")
                    .map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');

                var logObject = {
                    logID : objectID,
                    timeStamp: timeStamp,
                    record: record,
                    recordID : recordID,
                    actionType : actionType,
                    userID: user.uid
                }
                this.SaveTODB("Logs", objectID, logObject);

            }
        })
    }

}


export default new FireFetch();
