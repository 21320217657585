import React, {useEffect, useState } from 'react';
import {MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {Button, Card, Container, Text} from "@nextui-org/react";
import {Layout, Table} from "antd";
import { Input, Spacer } from "@nextui-org/react";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore} from "firebase/firestore";
import {ColumnsType} from "antd/lib/table";
import moment from 'moment';
import Firebase from "../../Firebase/Firebase";
import Headerbar from '../../navBars/Headerbar';
import Sidebar from '../../navBars/Sidebar';
import {generatePath, useNavigate} from "react-router-dom";


interface DataType {
    key: number;
    name: string;
    phone: string;
    userStatus: string;
    createdAt: string;
    action: JSX.Element;
}
const { Content } = Layout;
const db = getFirestore(Firebase);
const Buyers = () => {

    const columns: ColumnsType<DataType> = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Buyer',
            dataIndex: 'name',
            key: 'name',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Status',
            dataIndex: 'userStatus',
            key: 'userStatus',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action'
        },
    ];

// @ts-ignore

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [userArray, setUserArray] = useState<DataType[]>([]);
    const [userSnapshot, loading] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [dataArray, setDataArray] = useState<DataType[]>([]);

    useEffect(() => {
        if(userSnapshot){
            var tempArray: any[] = [];
            userSnapshot.docs.map((doc: { data: () => any; }) => {
                if(doc.data().type === "buyer"){
                    tempArray.push(doc.data());
                }

            });
            var emptyArray: DataType[] = [];
            tempArray.map((item, index) => {
                emptyArray.push({
                    key: index+1,
                    name: item.fullName,
                    phone: item.phoneNumberAuth,
                    userStatus: item.userStatus,
                    createdAt: moment(item.createdAt, "DD MMM YYYY").format("DD/MMM/YYYY"),
                    action : <Button size="xs" rounded color="primary"
                                     onClick={() => {
                                         navigate(generatePath('/buyers/:id', {id: item.Uid}));
                                     }}
                                     icon={<MDBIcon icon="eye"/>}/>
                });
            });

            setUserArray([...emptyArray]);
            setDataArray([...emptyArray]);
        }
    }, [userSnapshot]);

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>
                        <Container className="mt-2">
                            <MDBRow>
                                <MDBCol>
                                    <Card  className="my-2 p-2 font-JosefinSans overflow-hidden">
                                        <div className="d-flex pt-2 px-2 justify-content-between align-item-center">
                                            <Text h4 size={23}
                                                  css={{ color: "#29327b", fontWeight: "$bold", margin: 10 }}>
                                                Buyers
                                            </Text>
                                            <Input
                                                placeholder="search buyers"
                                                //onChange={(e) => { searchFilter(String(e.target.value))}}
                                                className="mx-1"
                                                contentRight={<MDBIcon icon="search"/>}
                                            />
                                        </div>
                                    </Card>
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <Table columns={columns}
                                               dataSource={dataArray} loading={loading}
                                               pagination={{ defaultPageSize: 20, showSizeChanger: false, pageSizeOptions: ['20']}}/>
                                    </Card>
                                </MDBCol>
                            </MDBRow>
                        </Container>
                    </Content>
                </Layout>
            </Layout>

        </>
    )
}

export default Buyers;
