import React, {useEffect, useRef, useState} from 'react';
import { Avatar } from "@nextui-org/react";
import shopped from "../images/shpped.png";
import shoppedLogo from "../images/shopped-mini.jpg";
import {Menu} from "primereact/menu";
import { getAuth, signOut } from "firebase/auth";
import Firebase from "../Firebase/Firebase"
import {useNavigate} from "react-router-dom";
import {useToast} from "@chakra-ui/react";
import {useAuthState} from "react-firebase-hooks/auth";
import {doc, getDoc, getFirestore} from "firebase/firestore";
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import {MDBCol, MDBRow} from "mdbreact";
import {getDownloadURL, getStorage, ref} from "firebase/storage";



const storage = getStorage(Firebase);
const db = getFirestore(Firebase);
const firebaseAuth = getAuth(Firebase);
type Props = {
    collapsed: boolean,
    setCollapsed: (value: boolean) => void;
};

const Headerbar: React.FC<Props>  = (props) => {
    const navigate = useNavigate();
    const menu = useRef(null);
    const toast = useToast()
    const [smallView, setSmallView] = useState(false);
    const mq = window.matchMedia('(max-width: 550px)');
    const [
        user, error
    ] = useAuthState(firebaseAuth);
    const [userDetails, setUserDetails] = useState<any>();
    const [username, setUsername] = useState("");
    const [imageUrl, setImageUrl] = useState<string>()
    const items = [
        
        {
            label: userDetails?.fullName,
            // label: userDetails?.firstname + " " + userDetails?.surname,
            items: [

                {
                    label: 'Logout',
                    icon: 'pi pi-times',
                    command: () => {
                        signOut(firebaseAuth).then(() => {
                            gotoLogin();
                        }).catch(()=>{
                            toast({
                                title: 'Logout Error',
                                description: "Unable to logout an error occurred",
                                status: 'error',
                                duration: 3000,
                                isClosable: true,
                            })
                        });
                    }
                }
            ]
        }
    ];

    if(user){
        const docRef = doc(db, "users", user.uid);
        getDoc(docRef).then((docSnap) => {
            const userObject = docSnap.data();
            var nameString = userObject?.fullName ;
            const fullName = nameString.split(' ');
            // @ts-ignore
            const initials = fullName.shift().charAt(0) ;


            //+ fullName.pop().charAt(0)
            setUsername(initials.toUpperCase());
            var tempObject = userObject as object;
            setUserDetails(tempObject);
        });
        // getDownloadURL(ref(storage, `profiles/${user.uid}`))
        //     .then((url) => {
        //         setImageUrl(url);
        //     });
    }

    if(error){
        navigate("/");
    }

    const gotoLogin = () => {
        navigate("/");
    }
    const toggle = () => {
        if (mq.matches) {
            setSmallView(true);
        } else {
            setSmallView(false);
        }
    }

    useEffect(() => {
        toggle();
        // returns true when window is <= 768px
        mq.addListener(toggle);

        // unmount cleanup handler
        return () => mq.removeListener(toggle);
    }, []);

    const start =
    <div className="">
        <MDBRow>
            <MDBCol>
                <div>
                    {smallView
                        ?
                        <img
                            src={shoppedLogo}
                            alt={"logo"}
                            className="border rounded-pill"
                            style={{ maxWidth: '10rem', maxHeight: '3rem'}}
                        />
                        :
                        <img
                            src={shopped}
                            alt={"logo"}
                            style={{ maxHeight: '5.5rem'}}
                        />
                    }
                </div>
            </MDBCol>
            <MDBCol>
                <div>
                    { !smallView
                        ?
                        <Button
                            icon="pi pi-bars" className="p-button-rounded p-button-outlined mx-4" aria-label="toggle"
                            onClick={() => {props.setCollapsed(!props.collapsed);}} color={"#29327b"}/>
                        :
                        null
                    }
                </div>
            </MDBCol>
        </MDBRow>



    </div>

    const end = <><Menu model={items} popup ref={menu} id="popup_menu" />
        <div className="d-flex align-items-center" onClick={(event) => {
            // @ts-ignore
            menu.current.toggle(event)
        }}>
            <Avatar
                size="md"
                src={imageUrl}
                zoomed
                className="text-white white-text"
                text={username}
                textColor="white"
                css={{fontWeight: "15em"}}
                color="gradient"
                pointer
                squared
            />
        </div></>
    return (
        <>
            <Menubar start={start} end={end} />
        </>
    )
}

export default Headerbar;
