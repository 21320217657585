import React, {useEffect, useRef, useState} from 'react';
import { Divider, Modal, Tooltip, Loading, Button, Card, Row, Text, Col, Spacer } from "@nextui-org/react";
import Headerbar from "../navBars/Headerbar";
import {Layout, List} from "antd";
import { useToast } from '@chakra-ui/react'
import placeholder from "../images/holder.jpg";
import Sidebar from "../navBars/Sidebar";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import Firebase from "../Firebase/Firebase";
import 'firebase/compat/firestore';
import {doc, getDoc, getFirestore, collection} from "firebase/firestore";
import { getDownloadURL, uploadBytes, getStorage, ref } from "firebase/storage";
import ChangeEmail from "../Drawers/Profile/ChangeEmail";
import ChangePassword from '../Drawers/Profile/ChangePassword';
import {useCollection} from "react-firebase-hooks/firestore";
import AddAdminUser from '../Drawers/Profile/AddAdminUser';
import {init} from "emailjs-com";
import Firefetch from "../Firebase/Firefetch";



const { Content } = Layout;
const db = getFirestore(Firebase);
const storage = getStorage(Firebase);
const firebaseAuth = getAuth(Firebase);
init("user_2JD2DZg8xAHDW7e9kdorr");
const Profile = () => {
    const toast = useToast();
    const [collapsed, setCollapsed] = useState(false);
    const [userDetails, setUserDetails] = useState<any>();
    const [user] = useAuthState(firebaseAuth);
    const [imageUrl, setImageUrl] = useState<string>();
    const fileUpload = useRef(null);
    const [imageLoading, setImageLoading] = useState(true);
    const [upLoading, setUpLoading] = useState(false);
    const [userData, setUserData] = useState([
        {
            title: 'Full Name',
            value: ""
        },
        {
            title: 'Email Address',
            value: ""
        },
        {
            title: 'User Role',
            value: ""
        }
    ]);
    const [editList] = useState([
        {
            title: 'Email Address',
            value: "edit your email"
        },
        {
            title: 'Password',
            value: "edit your password"
        },
    ]);
    const [emailChange, setEmailChange] = useState(false);
    const [passwordChange, setPasswordChange] = useState(false);
    const [showAdminDrawer, setShowAdminDrawer] = useState(false);
    const [userArray, setUserArray] = useState<any[]>([]);
    const [visible, setVisible] = useState(false);
    const [deleteID, setDeleteID] = useState<string>();
    const [userSnapshot, loading] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    useEffect(() => {
        if(user){
            const docRef = doc(db, "users", user.uid);
            getDoc(docRef).then((docSnap) => {
                const userObject = docSnap.data();
                var tempObject = userObject as object;

                var data = [
                    {
                        title: 'Full Name',
                        value: userObject?.fullName
                    },
                    {
                        title: 'Email Address',
                        value: userObject?.emailAuth
                    },
                    {
                        title: 'User Role',
                        value: userObject?.userRole
                    }
                ]

                getDownloadURL(ref(storage, `profiles/${userObject?.Uid}`))
                    .then((url) => {
                        setImageUrl(url);
                        setImageLoading(false);
                    }).catch(() =>{
                        console.log('Error downloading profile picture');
                    })
                setUserData(data);
                setUserDetails(tempObject);
            });
        }

        if(userSnapshot){
            var tempArray: any[] = [];
            userSnapshot.docs.map((doc: { data: () => any; }) => {
                if((doc.data().userRole === "Admin")){
                    tempArray.push(doc.data());
                }
            });
            setUserArray(tempArray);
        }

    }, [user, userSnapshot])

    const handleUpload = () => {
        // @ts-ignore
        fileUpload.current.click()
    };

    const uploadFile = (e: any) => {
        var file = e.target.files[0];
        if(file === undefined){
            setUpLoading(false);
            toast({
                title: 'No file chosen!',
                description: "Choose a file and try again",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } else {
            uploadBytes(ref(storage, `profiles/${userDetails.Uid}`), file).then(() => {
                setImageUrl(URL.createObjectURL(file));
                toast({
                    title: 'Picture uploaded!',
                    description: "Your profile picture has been updated",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                setUpLoading(false);
            });
        }

    };

    const handleChangeBtn = (choice : any) => {
        if(choice.title === "Email Address"){
            setEmailChange(true);
        } else if(choice.title === "Password"){
            setPasswordChange(true);
        }
    }

    const handleAddAdmin = () => {
        setShowAdminDrawer(true);
    }

    const deleteAdmin = () => {
        // @ts-ignore
        Firefetch.UpdateInDB("users", deleteID, {userStatus: "Disabled"}).then((result) => {
            if(result == "success"){
                setVisible(false);
                toast({
                    position: 'top',
                    title: 'User disabled successfully!',
                    description: "They will no longer have access to the system!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            }
        })
    }

    // @ts-ignore
    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content style={{ margin: '0 16px' }}>
                        <Modal
                            closeButton
                            preventClose
                            aria-labelledby="modal-title"
                            open={visible}
                            onClose={() => { setVisible(false) }}
                        >
                            <Modal.Body>
                                <Text id="modal-title" className="text-center" color="#FF0000" size={18}>
                                    Disable Admin?
                                </Text>
                                <Divider/>

                                <Text className="text-center">
                                    You will still be able to see the user but they will not have access to the system.
                                    This has been done for the preservation of data modified by the user in the system.
                                </Text>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button auto flat onClick={() => setVisible(false)}>
                                    Close
                                </Button>
                                <Button auto color="error" onPress={() => {
                                    deleteAdmin();
                                }}>
                                    Delete
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <ChangeEmail changeEmail={emailChange} closeEmail={setEmailChange}/>
                        <ChangePassword changePassword={passwordChange} closePassword={setPasswordChange}/>
                        <AddAdminUser openDrawer={showAdminDrawer} closeDrawer={setShowAdminDrawer}/>
                        <div className="site-layout-background mt-1" style={{ padding: 24, minHeight: 360 }}>
                            <input
                                type="file"
                                ref={fileUpload}
                                accept="image/*"
                                onChange={uploadFile}
                                style={{ opacity: "0" }}
                            />
                            <div>
                                <MDBRow gap={1}>
                                    <MDBCol md="6">
                                        <Card className="d-flex justify-content-center"
                                              style={{ height: 400 }} css={{ w: "100%", p: 0 }}>
                                            <Card.Body>
                                                { imageLoading ?
                                                    <Loading type="spinner" size="lg" />
                                                :
                                                    <Card.Image
                                                        src={imageUrl ? imageUrl : placeholder}
                                                        height={400}
                                                        width="100%"
                                                        alt="profile photo"
                                                    />
                                                }

                                            </Card.Body>
                                            <Card.Footer
                                                css={{
                                                    position: "absolute",
                                                    bottom: 0,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <Row>
                                                    <Col>
                                                        <Row justify="flex-end">
                                                            <Button
                                                                flat
                                                                auto
                                                                rounded
                                                                disabled={upLoading}
                                                                onPress={() => {
                                                                    setUpLoading(true);
                                                                    handleUpload()
                                                                }}
                                                                css={{ color: "#29327b", bg: "#bec1d7" }}
                                                            >
                                                                <Text
                                                                    css={{ color: "inherit" }}
                                                                    size={12}
                                                                    weight="bold"
                                                                    transform="uppercase"
                                                                >
                                                                    {
                                                                        upLoading ?
                                                                            <Loading type="spinner" color="currentColor" size="sm" />
                                                                             :
                                                                            <>Change</>
                                                                    }

                                                                </Text>
                                                            </Button>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Footer>
                                        </Card>
                                        <Card css={{ w: "100%" }}>
                                            <Card.Body className="font-JosefinSans">
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={userData}
                                                    renderItem={(item:any) => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                title={item.title}
                                                                description={item.value}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </Card.Body>
                                        </Card>
                                        <Spacer y={1} />
                                    </MDBCol>
                                    <MDBCol md="6">
                                        <MDBRow gap={1}>
                                            <MDBCol md="12">
                                                <Card css={{ w: "100%" }}>
                                                    <Card.Header className="font-JosefinSans">
                                                        <Text b>Edit Profile</Text>
                                                    </Card.Header>
                                                    <Divider />
                                                    <Card.Body className="font-JosefinSans">
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={editList}
                                                            renderItem={item => (
                                                                <List.Item
                                                                    actions={[
                                                                        <Button flat
                                                                                shadow
                                                                                rounded
                                                                                size="xs"
                                                                                onClick={()=>{
                                                                                    handleChangeBtn(item);
                                                                                }}>
                                                                            Change
                                                                        </Button>
                                                                    ]}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={item.title}
                                                                        description={item.value}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </Card.Body>
                                                </Card>

                                                <Spacer y={1} />

                                                <Card css={{ w: "100%" }} style={{maxHeight: "40rem"}}>
                                                    <Card.Header className="font-JosefinSans d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <Text b>Admins</Text>
                                                        </div>
                                                        <div>
                                                            <Tooltip content={"Add an admin"} rounded color="primary">
                                                                <Button shadow rounded size="xs" onClick={handleAddAdmin}>
                                                                    <i className="pi pi-plus-circle"/>
                                                                </Button>
                                                            </Tooltip>

                                                        </div>
                                                    </Card.Header>
                                                    <Divider />
                                                    <Card.Body className="font-JosefinSans">
                                                        <List
                                                            itemLayout="horizontal"
                                                            dataSource={userArray}
                                                            loading={loading}
                                                            renderItem={item => (
                                                                <List.Item
                                                                    actions={[
                                                                        <>
                                                                            {
                                                                                item.userStatus !== "Disabled"
                                                                                    ?
                                                                                    <MDBIcon icon="trash"
                                                                                             className="red-text text-danger"
                                                                                             onClick={() => {
                                                                                                 setVisible(true);
                                                                                                 setDeleteID(item.userID);
                                                                                             }}
                                                                                    /> : null
                                                                            }
                                                                        </>

                                                                    ]}
                                                                >
                                                                    <List.Item.Meta
                                                                        title={item.fullName}
                                                                        description={item.userStatus}
                                                                    />
                                                                </List.Item>
                                                            )}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </MDBCol>

                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                                <Spacer y={1} />

                            </div>

                        </div>
                    </Content>
                </Layout>
            </Layout>

        </>
    )
}

export default Profile;
